<script>
/**
 * Rules component
 */
export default {
  props: ["rules", "header"],
  components: {},
  data() {
    return {};
  },
  computed: {
    rulesItems() {
      return this.rules.map((rate) => ({ rate }));
    },
  },
  mounted() {},
  methods: {
    addRule() {
      this.rules.push("");
    },
    removeRule(index) {
      this.rules.splice(index, 1);
    },
  },
};
</script>

<template>
  <div>
    <b-table
      :items="rulesItems"
      :thead-class="header ? '' : 'd-none'"
      small
      class="mb-0"
    >
      <template #cell(time)=""></template>
      <template #cell(rate)="row">
        <b-input-group class="min-width-200">
          <b-input-group-prepend>
            <b-input-group-text>
              {{ row.index + 1 }}
            </b-input-group-text>
          </b-input-group-prepend>
          <b-select v-model="rules[row.index]">
            <b-select-option value=""></b-select-option>
            <b-select-option value="0">{{
              $t("rules.rate.0")
            }}</b-select-option>
            <b-select-option value="2">{{
              $t("rules.rate.2")
            }}</b-select-option>
            <b-select-option value="4">{{
              $t("rules.rate.4")
            }}</b-select-option>
            <b-select-option value="8">{{
              $t("rules.rate.8")
            }}</b-select-option>
            <b-select-option value="16">{{
              $t("rules.rate.16")
            }}</b-select-option>
            <b-select-option value="24">{{
              $t("rules.rate.24")
            }}</b-select-option>
            <b-select-option value="32">{{
              $t("rules.rate.32")
            }}</b-select-option>
            <b-select-option value="40">{{
              $t("rules.rate.40")
            }}</b-select-option>
          </b-select>
          <b-input-group-append>
            <b-button size="sm" variant="danger" @click="removeRule(row.index)">
              <i class="mdi mdi-trash-can-outline"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </template>
    </b-table>
    <b-button
      v-if="rules.length < 5"
      class="my-2"
      size="sm"
      variant="success"
      @click="addRule"
      ><i class="mdi mdi-plus"></i
    ></b-button>
  </div>
</template>