<script>
import _ from "lodash";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Rules from "./rules.vue";
import { workPlanMethods } from "../../../state/helpers";
import { mapGetters, mapMutations } from "vuex";

/**
 * Work Plan Details component
 */
export default {
  page: {
    title: "Work Plan",
  },
  components: {
    Layout,
    PageHeader,
    Rules,
  },
  data() {
    return {
      items: [
        {
          text: "HR",
          href: "/",
        },
        {
          text: "Work Plans",
          href: "/work_plans",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("workPlans", ["current_work_plan"]),
    rules() {
      const types = [
        "late_attendance",
        "early_leave",
        "extra_hours",
        "missing_hours",
        "off_days",
        "absence_days",
      ];

      return types.reduce((rules, type) => {
        rules[type] = _.find(this.current_work_plan.rules, { type }) ?? {};
        return rules;
      }, {});
    },
    schedule: {
      get() {
        return [
          {
            work_plan_schedule: "Work Period",
            ...this.current_work_plan?.schedule,
          },
          {
            work_plan_schedule: "Flexible Time",
            ...this.current_work_plan?.schedule,
          },
        ];
      },
    },
  },
  mounted() {},
  methods: {
    ...mapMutations("workPlans", ["SET_CURRENT_WORK_PLAN"]),
    ...workPlanMethods,
    toggleDay(selected, status) {
      console.log("toggleDay", status);
      if (!this.current_work_plan.schedule[selected]) {
        this.current_work_plan.schedule[selected] = {
          off: !status,
        };
      } else if (this.current_work_plan.schedule[selected].off === undefined) {
        this.current_work_plan.schedule[selected].off = !status;
      }
      this.SET_CURRENT_WORK_PLAN(
        _.mapValues(this.current_work_plan, (value, key) => {
          if (key === "schedule") {
            return _.mapValues(value, (dayObj, day) => {
              if (day === selected) {
                return { ...dayObj, off: !status };
              }
              return dayObj;
            });
          }
          return value;
        })
      );
    },
    toggleRule(selected, status) {
      console.log("toggleRule", status);
      this.SET_CURRENT_WORK_PLAN(
        _.mapValues(this.current_work_plan, (value, key) => {
          if (key === "rules") {
            return _.mapValues(value, (rule) => {
              if (rule.type === selected) {
                return { ...rule, is_active: status };
              }
              return rule;
            });
          }
          return value;
        })
      );
    },
    addRule(type) {
      console.log("addRule");
      if (type === "late_attendance" || type === "early_leave") {
        this.SET_CURRENT_WORK_PLAN(
          _.mapValues(this.current_work_plan, (value, key) => {
            if (key === "rules") {
              return _.mapValues(value, (rule) => {
                if (rule.type === type) {
                  return {
                    ...rule,
                    rules: [
                      ...rule.rules,
                      {
                        from:
                          rule.rules?.length > 0
                            ? parseInt(rule.rules.at(-1).to) + 1
                            : 1,
                        to:
                          rule.rules?.length > 0
                            ? parseInt(rule.rules.at(-1).to) + 2
                            : "",
                        same: 0,
                        penalties: [],
                      },
                    ],
                  };
                }
                return rule;
              });
            }
            return value;
          })
        );
      } else if (type === "extra_hours" || type === "missing_hours") {
        this.SET_CURRENT_WORK_PLAN(
          _.mapValues(this.current_work_plan, (value, key) => {
            if (key === "rules") {
              return _.mapValues(value, (rule) => {
                if (rule.type === type) {
                  return {
                    ...rule,
                    rules: [
                      ...rule.rules,
                      {
                        from:
                          rule.rules?.length > 0
                            ? parseInt(rule.rules.at(-1).to) + 1
                            : 1,
                        to:
                          rule.rules?.length > 0
                            ? parseInt(rule.rules.at(-1).to) + 2
                            : "",
                        rate: "",
                      },
                    ],
                  };
                }
                return rule;
              });
            }
            return value;
          })
        );
      }
    },
    removeRule(type, index) {
      this.SET_CURRENT_WORK_PLAN(
        _.mapValues(this.current_work_plan, (value, key) => {
          if (key === "rules") {
            return _.mapValues(value, (rule) => {
              if (rule.type === type) {
                return {
                  ...rule,
                  rules: rule.rules.filter((_, i) => i !== index),
                };
              }
              return rule;
            });
          }
          return value;
        })
      );
    },
    updateWorkPlan() {
      this.createWorkPlan({ ...this.current_work_plan });
    },
  },
  created() {
    if (this.$route.params.id !== "create") {
      this.getWorkPlans(this.$route.params.id);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="current_work_plan?.name" :items="items" />
    <b-card>
      <b-card-title>
        <b-row class="align-items-center">
          <b-col>
            Work Plan Name:
            <FormulateInput
              outer-class="d-inline-block"
              type="text"
              v-model="current_work_plan.name"
            />
          </b-col>
          <b-col md="auto">
            <b-button size="sm" variant="primary" @click="updateWorkPlan"
              >Update</b-button
            >
          </b-col>
        </b-row>
      </b-card-title>
      <b-table
        bordered
        :items="[
          {
            work_plan_schedule: 'work_period',
            sun: 0,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
          },
          {
            work_plan_schedule: 'flexible_time',
            sun: 0,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
          },
        ]"
        responsive="sm"
        class="mt-4 mb-0"
      >
        <template #head()="v">
          <b-form-checkbox
            v-if="v.column !== 'work_plan_schedule'"
            :checked="!current_work_plan.schedule[v.column]?.off"
            @input="(e) => toggleDay(v.column, e)"
            >{{ $t(`work_plans.${v.column}`) }}</b-form-checkbox
          >
        </template>
        <template #cell()="row">
          <div class="text-nowrap text-capitalize">
            <div v-if="typeof row.value === 'string'">
              {{ $t(`work_plans.${row.value}`) }}
            </div>
            <div
              v-else-if="
                current_work_plan.schedule[row.field.key] !== undefined &&
                !current_work_plan.schedule[row.field.key].off
              "
            >
              <span v-if="row.item?.work_plan_schedule === 'work_period'">
                <FormulateInput
                  type="time"
                  v-model="current_work_plan.schedule[row.field.key].start_time"
                />
                <FormulateInput
                  type="time"
                  v-model="current_work_plan.schedule[row.field.key].end_time"
                />
              </span>
              <span v-else>
                <FormulateInput
                  type="number"
                  min="0"
                  v-model="current_work_plan.schedule[row.field.key].start_flex"
                />
                <FormulateInput
                  type="number"
                  min="0"
                  v-model="current_work_plan.schedule[row.field.key].end_flex"
                />
              </span>
            </div>
            <div v-else>---</div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-card title="Rules">
      <b-row>
        <b-col md="6">
          <b-card
            v-for="(ruleType, index) in ['late_attendance', 'early_leave']"
            v-bind:key="index"
            bg-variant="light"
          >
            <b-card-title>
              <b-form-checkbox
                :checked="rules[ruleType].is_active"
                @input="(status) => toggleRule(ruleType, status)"
                >{{ $t(`rules.types.${ruleType}`) }}</b-form-checkbox
              >
              <!-- <span class="text-muted">{{
                rules[ruleType].effective_date | moment("DD/MM/YYYY")
              }}</span> -->
            </b-card-title>
            <b-card-body v-if="rules[ruleType]?.is_active">
              <b-table
                :items="rules[ruleType].rules"
                :fields="['from', 'to', 'penalties']"
                borderless
                small
                tbody-tr-class="border-top border-dark"
                class="mb-0"
              >
                <template #head()="head">
                  {{ head.label }}
                  <span v-if="head.column !== 'penalties'">(min)</span>
                </template>
                <template #cell(from)="row">
                  <FormulateInput
                    type="number"
                    min="0"
                    :disabled="row.index > 0"
                    v-model="rules[ruleType].rules[row.index].from"
                  />
                </template>
                <template #cell(to)="row">
                  <FormulateInput
                    type="number"
                    :disabled="rules[ruleType].rules.length !== row.index + 1"
                    :min="parseInt(rules[ruleType].rules[row.index].from) + 1"
                    :max="
                      rules[ruleType].rules.length === row.index + 1
                        ? undefined
                        : parseInt(rules[ruleType].rules[row.index + 1].from) -
                          1
                    "
                    v-model="rules[ruleType].rules[row.index].to"
                  />
                </template>
                <template #cell(penalties)="row">
                  <b-button
                    v-if="rules[ruleType].rules.length === row.index + 1"
                    size="sm"
                    variant="danger"
                    @click="removeRule(ruleType, row.index)"
                    ><i class="mdi mdi-trash-can"></i
                  ></b-button>
                  <Rules :rules="row.value" />
                </template>
              </b-table>
              <div class="text-center border-top border-dark pt-3">
                <b-button variant="success" size="sm" @click="addRule(ruleType)"
                  >Add Rule</b-button
                >
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card bg-variant="light" title="Extra &amp; Missing Hours">
            <b-card
              v-for="ruleType in ['extra_hours', 'missing_hours']"
              v-bind:key="ruleType"
            >
              <b-card-title>
                <b-form-checkbox
                  :checked="rules[ruleType].is_active"
                  @input="(status) => toggleRule(ruleType, status)"
                  >{{ $t(`rules.types.${ruleType}`) }}</b-form-checkbox
                >
              </b-card-title>
              <b-table
                v-if="rules[ruleType]?.is_active"
                :items="rules[ruleType].rules"
                :fields="['from', 'to', 'rate']"
                borderless
                small
                tbody-tr-class="border-top border-dark"
                class="mb-0"
              >
                <template #head()="head">
                  {{ $t(head.label) }}
                  <span v-if="head.column !== 'rate'">(hour)</span>
                </template>
                <template #cell(from)="row">
                  <FormulateInput
                    type="number"
                    min="0"
                    :disabled="row.index > 0"
                    v-model="rules[ruleType].rules[row.index].from"
                  />
                </template>
                <template #cell(to)="row">
                  <FormulateInput
                    type="number"
                    :disabled="rules[ruleType].rules.length !== row.index + 1"
                    :min="parseInt(rules[ruleType].rules[row.index].from) + 1"
                    :max="
                      rules[ruleType].rules.length === row.index + 1
                        ? undefined
                        : parseInt(rules[ruleType].rules[row.index + 1].from) -
                          1
                    "
                    v-model="rules[ruleType].rules[row.index].to"
                  />
                </template>
                <template #cell(rate)="row">
                  <b-input-group>
                    <b-input
                      size="sm"
                      type="number"
                      min="1"
                      v-model="rules[ruleType].rules[row.index].rate"
                    />
                    <b-input-group-append>
                      <b-button
                        v-if="rules[ruleType].rules.length === row.index + 1"
                        size="sm"
                        variant="danger"
                        @click="removeRule(ruleType, row.index)"
                        ><i class="mdi mdi-trash-can"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </template>
              </b-table>
              <div
                class="text-center border-top border-dark pt-3"
                v-if="rules[ruleType].is_active"
              >
                <b-button variant="success" size="sm" @click="addRule(ruleType)"
                  >Add Rule</b-button
                >
              </div>
            </b-card>
          </b-card>
          <b-card bg-variant="light">
            <b-card-title>
              <b-form-checkbox
                :checked="rules.off_days.is_active"
                @input="(status) => toggleRule('off_days', status)"
                >{{ $t(`rules.types.off_days`) }}</b-form-checkbox
              >
            </b-card-title>
            <b-table
              v-if="rules.off_days?.is_active"
              :items="rules.off_days.rules"
              :fields="['time', 'rate']"
              borderless
              small
              tbody-tr-class="border-top border-dark"
              class="mb-0"
            >
              <template #cell(time)="row">
                {{ $t(`rules.time.${row.value}`) }}
              </template>
              <template #cell(rate)="row">
                <FormulateInput
                  type="number"
                  min="1"
                  v-model="rules.off_days.rules[row.index].rate"
                />
              </template>
            </b-table>
          </b-card>
          <b-card bg-variant="light">
            <b-card-title>
              <b-form-checkbox
                :checked="rules.absence_days.is_active"
                @input="(status) => toggleRule('absence_days', status)"
                >{{ $t(`rules.types.absence_days`) }}</b-form-checkbox
              >
            </b-card-title>
            <Rules
              v-if="rules.absence_days?.is_active"
              :rules="rules.absence_days.rules"
              :header="false"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>